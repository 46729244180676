import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUser } from "../hooks/useUser";

interface HapticTouchContextProps {
  isHapticTouch: boolean;
  setIsHapticTouch: (value: boolean) => void;
}

interface HapticTouchProviderProps {
  children: ReactNode;
}

const HapticTouchContext = createContext<HapticTouchContextProps | undefined>(
  undefined
);

export const HapticTouchProvider: FC<HapticTouchProviderProps> = ({
  children,
}) => {
  const { user } = useUser();
  const [isHapticTouch, setIsHapticTouch] = useState<boolean>(
    user?.isHapticTouch || false
  );

  const fetchHapticTouch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/users/haptic-feedback/${user?.id}`
      );

      if (response.ok) {
        const data = await response.json();
        setIsHapticTouch(data.isHapticTouch);
      } else {
        console.error("Failed to fetch haptic feedback status");
      }
    } catch (error) {
      console.error("Error fetching haptic feedback:", error);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchHapticTouch();
    }
  }, []);

  const value = useMemo(
    () => ({ isHapticTouch, setIsHapticTouch }),
    [isHapticTouch, setIsHapticTouch]
  );

  return (
    <HapticTouchContext.Provider value={value}>
      {children}
    </HapticTouchContext.Provider>
  );
};
export const useHapticTouch = () => {
  const context = useContext(HapticTouchContext);
  if (!context) {
    throw new Error("useHapticTouch must be used within a HapticTouchProvider");
  }
  return context;
};
