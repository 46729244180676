import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../hooks/useUser";
import { useWebSocket } from "../../hooks/useWebsocket";
import { League, User } from "../../models";
import { formatNumberWithCommas } from "../../utils/formatNumber";
import { TopControl } from "../../components/TopControl";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { levelBaseImages } from "../../utils/levelBaseImages";

export const LeagueTop = () => {
  let bottomNavHeight = 80;
  const { user } = useUser();
  const { webSocket } = useWebSocket();
  const [league, setLeague] = useState<League | null>(null);
  const [usersInLeague, setUsersInLeague] = useState(0);
  const [topUsersInLeague, setTopUsersInLeague] = useState<User[]>([]);
  const [sortedUsers, setSortedUsers] = useState<User[]>([]);
  const [loggedInUser, setLoggedInUser] = useState<User>();
  const [loggedInUserIndex, setLoggedInUserIndex] = useState<
    number | undefined
  >();
  const [allLeagues, setAllLeagues] = useState<League[]>([]);
  const [currentLeagueIndex, setCurrentLeagueIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [isUserInView, setIsUserInView] = useState(true);
  // const userItemRef = useRef(null);
  useEffect(() => {
    const sorted = [...topUsersInLeague].sort((a, b) => b.balance - a.balance);
    setSortedUsers(sorted);
    const loggedInUser = sorted.find((leagueUser: any, i: number) => {
      return leagueUser._id == user?.id;
    });
    const loggedInUserIndex = sorted.findIndex(
      (leagueUser: any) => leagueUser?._id == user?.id
    );
    setLoggedInUserIndex(loggedInUserIndex);
    setLoggedInUser(loggedInUser);
  }, [topUsersInLeague]);
  useEffect(() => {
    const fetchAllLeagues = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/admin/leagues`
      );
      const data = await response.json();
      setAllLeagues(data);
      if (data.length > 0 && user) {
        const userLeagueIndex = data.findIndex(
          (league: League) => league.id === user.league.id
        );
        if (userLeagueIndex !== -1) {
          setCurrentLeagueIndex(userLeagueIndex);
        }
      }
      setLoading(false);
    };

    fetchAllLeagues();
  }, []);

  useEffect(() => {
    if (webSocket && allLeagues.length > 0) {
      const selectedLeagueId = allLeagues[currentLeagueIndex]?.id;

      const fetchLeagueInfo = () => {
        setLoading(true);
        webSocket.emit("getLeagueInfo", {
          leagueId: selectedLeagueId,
          topUsersCount: 10,
          userId: user?.tgId,
        });

        webSocket.on("league", (data) => {
          if (!data.error) {
            setLeague(data.league);
            setUsersInLeague(data.usersInLeague);
            setTopUsersInLeague(data.topUsersInLeague);
          }
          setLoading(false);
        });
      };

      fetchLeagueInfo();

      return () => {
        webSocket.off("league");
      };
    }
  }, [webSocket, currentLeagueIndex, allLeagues]);

  const handleNextLeague = () => {
    setCurrentLeagueIndex((prevIndex) =>
      prevIndex < allLeagues.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePreviousLeague = () => {
    setCurrentLeagueIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const balance = user?.balance || 0;
  const maxScore = league?.maxScore || 0;
  const progressPercentage = maxScore > 0 ? (balance / maxScore) * 100 : 0;

  function formatNumberWithK(number: number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    }
    return number.toFixed(0);
  }

  useEffect(() => {
    const adjustBottom = () => {
      const bottomNav = document.getElementById("bottom-nav");
      if (bottomNav) {
        bottomNavHeight = bottomNav.offsetHeight;
      }
    };

    adjustBottom();
    window.addEventListener("resize", adjustBottom);

    return () => {
      window.removeEventListener("resize", adjustBottom);
    };
  }, []);

  return (
    <div className="flex flex-col relative">
      <TopControl name="Profile Level" />
      <div className="relative pt-0 px-4 rounded-lg">
        <div className="px-2 w-full relative flex items-center justify-between">
          <button
            onClick={handlePreviousLeague}
            disabled={currentLeagueIndex === 0}
            className={`text-4xl font-bold ${
              currentLeagueIndex === 0 ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            <FaChevronLeft />
          </button>
          {loading ? (
            <div className="rounded w-48 h-48 bg-gray-300 bg-opacity-25 animate-pulse"></div>
          ) : (
            <div className="relative flex items-center justify-center">
              <img
                src="./img/circles.png"
                className="w-8/12 absolute inset-0 m-auto -z-[1]"
              />
              <img
                src={league?.avatarUrl}
                alt={league?.name}
                className="max-w-[100px]"
              />
            </div>
          )}
          <button
            onClick={handleNextLeague}
            disabled={currentLeagueIndex === allLeagues.length - 1}
            className={`text-4xl font-bold ${
              currentLeagueIndex === allLeagues.length - 1
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
          >
            <FaChevronRight />
          </button>
        </div>
        {loading ? (
          <div className="flex flex-col items-center mt-4">
            <div className="rounded w-[50%] h-7 bg-gray-300 bg-opacity-25 animate-pulse"></div>
            <div className="rounded w-[70%] bg-gray-300 bg-opacity-25 animate-pulse mt-1"></div>
          </div>
        ) : (
          <div className="flex flex-col items-center mt-4">
            <div className="uppercase text-base">{league?.name}</div>
            <div className="flex gap-1 text-sm text-[#515A70]">
              <div>{formatNumberWithK(user?.balance || 0)}</div>
              <div>/</div>
              <div>{formatNumberWithK(league?.maxScore || 0)}</div>
            </div>

            <div className="relative w-full mt-2 h-2 bg-[#092C4E] rounded-full overflow-hidden">
              <div
                className="absolute top-0 left-0 h-full bg-[#288CEE] rounded-full"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>
        )}
        {loading ? (
          <div className="flex justify-center items-center mt-8">
            <div className="loader-modal" />
          </div>
        ) : (
          <div className="rounded-xl py-4 mx-2 overflow-auto  no-scrollbar">
            <div className="text-center mb-2">Top Users in League</div>
            <ul className="list-none w-full relative">
              {sortedUsers.length > 0 ? (
                sortedUsers.map((userL: User, index: number) => (
                  <li
                    key={userL.tgId}
                    // ref={userL.tgId === user?.tgId ? userItemRef : null}
                    className={`flex justify-between items-center rounded-3xl px-2 py-1 my-1 shadow-sm ${
                      userL.tgId === user?.tgId
                        ? `bg-[#222429] border-[3px] border-[#288CEE] `
                        : "bg-[#333A48B2]"
                    }`}
                  >
                    <div className="flex items-start gap-4 p-2">
                      <div className="w-8 h-8 rounded-lg">
                        {user &&
                        user?.userLevel > 0 &&
                        user?.userLevel <= levelBaseImages.length ? (
                          <img
                            src={levelBaseImages[user.userLevel - 1]}
                            alt={`User Level ${user.userLevel}`}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        ) : (
                          <img
                            src="/img/profile-images/1.png"
                            alt={`User Level 1`}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        )}
                      </div>
                      <div className="flex flex-col text-sm">
                        <div className="flex items-center gap-3">
                          <img
                            src="./img/binance-icon.png"
                            className="w-5 h-5"
                          />
                          <div>{userL.firstName || "Anonymous"}</div>
                        </div>
                        <div className="mt-1 flex items-center gap-3">
                          <img src="./img/coin.png" className="w-5 h-5" />
                          <div className="text-base font-bold text-[#E0AE10]">
                            {formatNumberWithCommas(userL.balance)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="size-7 shrink-0 flex items-center justify-center text-lg font-bold text-white bg-[#0F74D6] rounded-full">
                      {index + 1}
                    </div>
                  </li>
                ))
              ) : (
                <div className="text-center mt-4">No data available</div>
              )}
            </ul>
            {sortedUsers && sortedUsers.length > 0 && loggedInUser && (
              <div
                style={{ bottom: bottomNavHeight + 10 }}
                className={`fixed w-[89%] left-1/2 -translate-x-1/2 flex justify-between items-center rounded-3xl px-2 py-1 my-1 shadow-sm bg-[#222429] border-[3px] border-[#288CEE]  z-40`}
              >
                <div className="flex items-start gap-4 p-2">
                  <div className="w-8 h-8 rounded-lg">
                    {loggedInUser &&
                    loggedInUser?.userLevel > 0 &&
                    loggedInUser?.userLevel <= levelBaseImages.length ? (
                      <img
                        src={levelBaseImages[loggedInUser.userLevel - 1]}
                        alt={`User Level ${loggedInUser.userLevel}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    ) : (
                      <img
                        src="/img/profile-images/1.png"
                        alt={`User Level 1`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    )}
                  </div>
                  <div className="flex flex-col text-sm">
                    <div className="flex items-center gap-3">
                      <img src="./img/binance-icon.png" className="w-5 h-5" />
                      <div>{loggedInUser?.firstName || "Anonymous"}</div>
                    </div>
                    <div className="mt-1 flex items-center gap-3">
                      <img src="./img/coin.png" className="w-5 h-5" />
                      <div className="text-xl font-bold text-[#E0AE10]">
                        {formatNumberWithCommas(loggedInUser?.balance || 0)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="size-10 shrink-0 flex items-center justify-center text-2xl font-bold text-white bg-[#0F74D6] rounded-full">
                  {typeof loggedInUserIndex == "number"
                    ? loggedInUserIndex + 1
                    : 1}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
