export const TaskList = ({
  tasks,
  handleTaskClick,
  filter,
}: {
  tasks: any;
  handleTaskClick: any;
  filter: string;
}) => {
  const filteredTasks = tasks?.filter((task: any) => task.active === true);

  return (
    <>
      {(!tasks || tasks.length === 0) && (
        <>
          <li className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm animate-pulse">
            <div className="w-full flex flex-row justify-left items-center">
              <div className="mr-2 rounded-full w-[55px] h-[55px] bg-gray-700"></div>
              <div className="flex flex-col gap-2">
                <div className="w-32 h-2 bg-gray-700 rounded"></div>
                <div className="flex items-center gap-2">
                  <div className="w-5 h-2 bg-gray-700 rounded"></div>
                  <div className="w-20 h-1 bg-gray-700 rounded"></div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm animate-pulse">
            <div className="w-full flex flex-row justify-left items-center">
              <div className="mr-2 rounded-full w-[55px] h-[55px] bg-gray-700"></div>
              <div className="flex flex-col gap-2">
                <div className="w-32 h-2 bg-gray-700 rounded"></div>
                <div className="flex items-center gap-2">
                  <div className="w-5 h-2 bg-gray-700 rounded"></div>
                  <div className="w-20 h-1 bg-gray-700 rounded"></div>
                </div>
              </div>
            </div>
          </li>
        </>
      )}

      {filteredTasks?.map((task: any) => (
        <li
          key={task.id}
          className={`p-1 my-2 border-2 bg-[#333A48B2] overflow-hidden rounded-2xl flex justify-between items-center shadow-sm relative ${
            task.completed ? "border-transparent" : "border-[#7387A8]"
          }`}
          onClick={() => handleTaskClick(task)}
        >
          {task.completed && (
            <div className="w-full h-full absolute top-0 left-0 text-white bg-[#333A48] font-bold bg-opacity-60 flex justify-center items-center pointer-events-none">
              Redeemed
            </div>
          )}
          <div className="w-full flex flex-row justify-left items-center">
            <img
              src={task.avatarUrl}
              className="mr-2 rounded-full w-16 h-16 p-2 object-scale-down"
              alt="task-avatar"
            />
            <div>
              <span
                className={
                  task.completed
                    ? "text-xs font-light	text-gray-500 line-through leading-none line-clamp-1"
                    : "text-xs font-light text-white leading-none line-clamp-1"
                }
              >
                {task.name}
              </span>
              <span className="flex justify-left mt-2 gap-2 items-center">
                <div className="flex gap-2 items-center">
                  <div className="size-2 bg-[#E0AE10] rounded-full"></div>
                  <img
                    src="./img/coin.png"
                    className="w-5 h-5"
                    alt="coin-icon"
                  />
                </div>
                <div className="text-xs text-[#E0AE10] font-bold">
                  +{task.rewardAmount}
                </div>
                <div className="text-xs text-[#677496] font-bold ml-1">
                  Billies
                </div>
              </span>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};
