import React, { useState } from "react";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

export const AdminAddBusiness = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [rewardPerHour, setRewardPerHour] = useState(0);
  const [refsToUnlock, setRefsToUnlock] = useState(0);
  const [price, setPrice] = useState(0);
  const [category, setCategory] = useState("ASSETS");

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${adminApiUrl}/admin/businesses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Admin-Token": localStorage.getItem("password") || "",
        },
        body: JSON.stringify({
          name,
          description,
          avatarUrl,
          rewardPerHour,
          refsToUnlock,
          price,
          category,
        }),
      });

      if (response.ok) {
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setErrorText(await response.text());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col space-y-2 text-black">
      <input
        type="text"
        placeholder="Business name"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setName(e.target.value)}
      />

      <textarea
        placeholder="Description"
        className="bg-slate-50 py-1 px-4 w-full outline-none resize-none h-36"
        onChange={(e) => setDescription(e.target.value)}
      />

      <input
        type="text"
        placeholder="Avatar link"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setAvatarUrl(e.target.value)}
      />

      <input
        type="number"
        placeholder="Reward per hour"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setRewardPerHour(Number(e.target.value))}
      />

      <input
        type="number"
        placeholder="Refs to unlock"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setRefsToUnlock(Number(e.target.value))}
      />

      <input
        type="number"
        placeholder="Price"
        className="bg-slate-50 py-1 px-4 w-full outline-none"
        onChange={(e) => setPrice(Number(e.target.value))}
      />
      <select
        onChange={(e) => setCategory(e.target.value)}
        className="bg-slate-50 py-1 px-4 w-full outline-none"
      >
        <option value="Consoles" className="uppercase">
          Consoles
        </option>
        <option value="Games" className="uppercase">
          Games
        </option>
        <option value="Peripherals" className="uppercase">
          Peripherals
        </option>
        <option value="Personalities" className="uppercase">
          Personalities
        </option>
      </select>

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-light py-1 px-4 w-full font-mono"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "CONFIRM"}
      </button>

      {isSuccess && (
        <div className="bg-green-400 text-center text-white">
          Successfully added
        </div>
      )}
      {isError && (
        <div className="bg-red-600 text-center text-white">
          Error occurred. {errorText}
        </div>
      )}
    </div>
  );
};
