import { Link, useNavigate } from "react-router-dom";

export const TopControl = ({ name }: { name: string }) => {
  const navigate = useNavigate();

  const handleNavigateToMainPage = () => {
    navigate("/");
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <div
      id="top-control"
      className="w-full flex justify-between items-center p-2"
    >
      <svg
        onClick={handleBackButtonClick}
        width="35"
        height="35"
        viewBox="0 0 82 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="10.5" y="10" width="61" height="61" rx="30.5" fill="#273148" />
        <rect
          x="5.5"
          y="5"
          width="71"
          height="71"
          rx="35.5"
          stroke="#273148"
          strokeOpacity="0.3"
          strokeWidth="10"
        />
        <rect
          x="44"
          y="28"
          width="3.92836"
          height="17.6776"
          rx="1.96418"
          transform="rotate(45 44 28)"
          fill="#C0C9DD"
        />
        <rect
          x="46.7778"
          y="50.2222"
          width="3.92836"
          height="17.6776"
          rx="1.96418"
          transform="rotate(135 46.7778 50.2222)"
          fill="#C0C9DD"
        />
      </svg>
      <div className="font-extrabold uppercase text-xl">{name}</div>
      {window.location.pathname.includes("/settings") ? (
        <div></div>
      ) : (
        <Link to={"/settings"} className="cursor-pointer">
          <svg
            width="35"
            height="35"
            viewBox="0 0 81 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="10"
              y="10"
              width="61"
              height="61"
              rx="30.5"
              fill="#273148"
            />
            <rect
              x="5"
              y="5"
              width="71"
              height="71"
              rx="35.5"
              stroke="#273148"
              strokeOpacity="0.3"
              strokeWidth="10"
            />
            <circle cx="24.5" cy="40.5" r="4" fill="#C0C9DD" />
            <circle cx="40.5" cy="40.5" r="4" fill="#C0C9DD" />
            <circle cx="56.5" cy="40.5" r="4" fill="#C0C9DD" />
          </svg>
        </Link>
      )}
    </div>
  );
};
